import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} right-4 sm:right-6 md:right-10 lg:right-14`}
      style={{ ...style, display: "block", zIndex: 10 }}
      onClick={onClick}
    />
  );
};

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} left-4 sm:left-6 md:left-10 lg:left-14`}
      style={{ ...style, display: "block", zIndex: 10 }}
      onClick={onClick}
    />
  );
};

const TestimoniesCarousel = () => {
  const testimonies = [
    {
      name: 'Stefania',
      occupation: 'Alumno de Programación',
      testimony: 'Muy buen maestro, explica super bien!!'
    },
    {
      name: 'Francesco',
      occupation: 'Alumno de Programación',
      testimony: 'Muy buenas clases. Franco se maneja en los contenidos y es muy claro a la hora de explicar. Lo recomiendo'
    },
    {
      name: 'Alumno de Ruby on Rails',
      occupation: 'Alumno de Ruby on Rails',
      testimony: 'Todo perfecto, lo recomiendo'
    },
    {
      name: 'Luke',
      occupation: 'Alumno de Ruby on Rails',
      testimony: 'Excellent'
    },
    {
      name: 'Ai',
      occupation: 'Alumno de Programación',
      testimony: 'He is very knowledgeable and I am looking forward to taking next sessions with him.'
    },
    {
      name: 'Alumno de Programación',
      occupation: 'Alumno de Programación',
      testimony: 'Franco, is a very good teacher. He explains the concepts very well and is patient. I would highly recommend Franco.'
    },
    {
      name: 'Areli S',
      occupation: 'Alumno de Acordeón',
      testimony: 'La primera clase me ha gustado bastante, con buena preparación, excelentes explicaciones y sencillas, con mucha paciencia.'
    },
    {
      name: 'Leticia',
      occupation: 'Alumno de Acordeón',
      testimony: 'Muy buen profesor, dedicado y con mucha paciencia. Sus clases son muy amenas.'
    },
    {
      name: 'Karl Walter',
      occupation: 'Alumno de Producción Musical',
      testimony: 'Excelente profesor, muy buena la clase, 100% recomendado.'
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  return (
    <section className="py-16 bg-[#0B0D17]" id="testimonies">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-4xl font-extrabold text-[#00D7A3] text-center mb-8">
          Testimonios
        </h2>
        <p className="text-lg text-[#B2B6C0] text-center mb-12 max-w-3xl mx-auto">
          Estoy apasionado por compartir mi conocimiento y creo que tener a alguien experto en enseñanza es un activo valioso para cualquier equipo. Ofrezco clases de programación a través de mi cuenta de <a href="https://www.superprof.mx/orientacion-asesoramiento-para-estudiantes-informatica-aprende-crear-aplicaciones-con-ruby-rails-javascript-css-html.html" className="underline hover:text-[#00D7A3]">Superproof</a>, donde puedes ver testimonios de mis estudiantes.
        </p>

        <Slider {...settings}>
          {testimonies.map((item, index) => (
            <div key={index} className="flex items-center justify-center p-6 text-center bg-[#1C1F36] rounded-lg shadow-xl hover:shadow-2xl transition-shadow duration-300 min-h-[200px]">
              <div>
                <p className="text-2xl font-semibold text-[#00D7A3]">{item.name}</p>
                <p className="text-[#B2B6C0] mb-2">{item.occupation}</p>
                <p className="text-[#B2B6C0] italic line-clamp-3">"{item.testimony}"</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default TestimoniesCarousel;